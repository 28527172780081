/**
 * Shift all coordinates in a GeoJSON by offsetX, offsetY.
 * Assumes coordinates are in [lng, lat] order.
 *
 * @param {Object} geojson     - A valid GeoJSON object (FeatureCollection, Feature, or Geometry).
 * @param {number} offsetX     - The amount to add to all longitude values.
 * @param {number} offsetY     - The amount to add to all latitude values.
 * @returns {Object}           - A new GeoJSON object with shifted coordinates.
 */
function shiftGeoJSON(geojson, offsetX, offsetY) {
// If it's a FeatureCollection, shift each Feature
if (geojson.type === 'FeatureCollection') {
    return {
    ...geojson,
    features: geojson.features.map(feature => shiftGeoJSON(feature, offsetX, offsetY))
    };
}

// If it's a Feature, shift its geometry
if (geojson.type === 'Feature') {
    return {
    ...geojson,
    geometry: shiftGeoJSON(geojson.geometry, offsetX, offsetY)
    };
}

// If it's a single Geometry, shift coordinates based on geometry type
if (geojson.type === 'Point') {
    return {
    ...geojson,
    coordinates: shiftCoordinate(geojson.coordinates, offsetX, offsetY)
    };
}

if (geojson.type === 'LineString' || geojson.type === 'MultiPoint') {
    return {
    ...geojson,
    coordinates: geojson.coordinates.map(coords =>
        shiftCoordinate(coords, offsetX, offsetY)
    )
    };
}

if (geojson.type === 'Polygon' || geojson.type === 'MultiLineString') {
    // Polygon -> array of linear rings
    // MultiLineString -> array of line strings
    return {
    ...geojson,
    coordinates: geojson.coordinates.map(ringOrLine =>
        ringOrLine.map(coords => shiftCoordinate(coords, offsetX, offsetY))
    )
    };
}

if (geojson.type === 'MultiPolygon') {
    // array of polygons -> each polygon is array of rings -> each ring is array of coords
    return {
    ...geojson,
    coordinates: geojson.coordinates.map(polygon =>
        polygon.map(ring =>
        ring.map(coords => shiftCoordinate(coords, offsetX, offsetY))
        )
    )
    };
}

// If some other geometry type (GeometryCollection, etc.), you'd handle them similarly
// but for simplicity, let's just return as-is
return geojson;
}

/**
 * Shift a single [lng, lat] coordinate pair by offsetX, offsetY.
 * @param {number[]} coord  - [lng, lat]
 * @param {number} offsetX  - shift in longitude
 * @param {number} offsetY  - shift in latitude
 * @returns {number[]}      - new [lng, lat]
 */
function shiftCoordinate(coord, offsetX, offsetY) {
// coord[0] => lng, coord[1] => lat
return [coord[0] + offsetX, coord[1] + offsetY];
}

export { shiftGeoJSON };