import React, { useState, useMemo } from 'react';
import { Grid, Container } from 'semantic-ui-react';

function HitCalc(props) {
  const [hr, setHr] = useState(40);
  const [ac, setAc] = useState(-300);
  const [weaponSkill, setWeaponSkill] = useState(100)
  const [canSeeAttackWeapon, setCanSeeAttackWeapon] = useState(true) 
  const [canSeeVictim, setCanSeeVictim] = useState(true)
  const [isVictimAwake, setVictimAwake] = useState(true)
  const [isAttackerDefel, setAttackerDefel] = useState(false)

  const thac0 = useMemo(() => -26 - hr, [hr]);
  const victim_ac = useMemo(() => 
    ac / 15 +
    (!canSeeAttackWeapon ? 1 : 0) +
    (!canSeeVictim ? -4 : 0) +
    (isAttackerDefel ? 2 : 0) +
    (!isVictimAwake ? 5 : 0) +
    (weaponSkill / 20), 
    [ac, canSeeAttackWeapon, canSeeVictim, isAttackerDefel, isVictimAwake, weaponSkill]
  );

  // percentage of success for d20 >= thac0 - victim_ac
  const success = useMemo(() => (21 - (thac0 - victim_ac)) / 20 * 100, [thac0, victim_ac]);

  const Field = ({label, value, children}) => {
    return (
      <div>
        {label}: {value}
        <br/>
        {children}
        <br/>
        <hr/>
      </div>
    )
  }
  return (
    <div className='App'>
      <Container>
        <h1>Hit calculator</h1>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Field label="HR" value={hr}>
                <input
                  type='range'
                  defaultValue={hr}
                  onChange={e => setHr(e.target.value)}
                  min='0'
                  max='100'
                  step='5'
                />
              </Field>
              <Field label="AC" value={ac}>
                <input
                  type='range'
                  value={ac}
                  onChange={e => setAc(e.target.value)}
                  min='-900'
                  max='100'
                  step='50'
                />
              </Field>        
              <Field label="Weapon skill" value={weaponSkill}>
                <input
                  type='range'
                  value={weaponSkill}
                  onChange={e => setWeaponSkill(e.target.value)}
                  min='0'
                  max='100'
                  step='10'
                />
              </Field>
              <Field label="Is victim awake" value={isVictimAwake}>
                <input
                  type='checkbox'
                  checked={isVictimAwake}
                  onChange={e => setVictimAwake(e.target.checked)}
                />
              </Field>
              <Field label="Is attacker defel" value={isAttackerDefel}>
                <input
                  type='checkbox'
                  checked={isAttackerDefel}
                  onChange={e => setAttackerDefel(e.target.checked)}
                />
              </Field>              
              <Field label="Can see attacker's weapon" value={canSeeAttackWeapon}>
                <input
                  type='checkbox'
                  checked={canSeeAttackWeapon}
                  onChange={e => setCanSeeAttackWeapon(e.target.checked)}
                />
              </Field>
              <Field label="Can see victim" value={canSeeVictim}>
                <input
                  type='checkbox'
                  checked={canSeeVictim}
                  onChange={e => setCanSeeVictim(e.target.checked)}
                />
              </Field>                  
            </Grid.Column>

            <Grid.Column>
              <Field label="THAC0 (-26 - HR)" value={thac0}/>
              <Field label="Modified Victim AC" value={victim_ac}/>
              <Field label="Target for d20 roll" value={thac0 - victim_ac}/>
              <Field label="Chance to hit (d20 >= thac0 - victim_ac)" value={`${success}%`}/>            
            </Grid.Column>
          </Grid.Row>
        </Grid>




      </Container>
    </div>
  );
}

export default HitCalc;