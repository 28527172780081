import * as React from 'react'
// import * as Leaflet from 'react-leaflet'
import L from 'leaflet';
import { MapContainer, TileLayer, useMap, Marker, Popup, ImageOverlay, Circle, Polygon, Polyline } from 'react-leaflet'
import { Icon } from 'leaflet';
import { Container } from 'semantic-ui-react';
import 'leaflet/dist/leaflet.css';
// import './map.css'
// import {
//   QueryClient,
//   QueryClientProvider,
//   useQuery,
// } from '@tanstack/react-query';
import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';

import { GeoJSON } from 'react-leaflet';
// geodata in public/points_and_routes.geojson
import geoData from '../../../public/points_and_routes.geojson';
// import { useState } from 'react';
import { shiftGeoJSON } from './geojson_utils';

const shiftedGeoData = shiftGeoJSON(geoData, 128, -128);

const Sector = (props) => {
  // console.log("Sector props:", props);
  const coordinates = JSON.parse(props.sector.coordinates).map((coordinate) => {
    return [
      parseInt(coordinate[0]) * parseFloat(props.scale) + parseInt(props.offsetX),
      parseInt(coordinate[1]) * parseFloat(props.scale) + parseInt(props.offsetY)
    ];
  });
  // color is in RGB format, sector.r, sector.g, sector.b
  const color = `grey`;
  return (
    <Polygon pathOptions={{color: props.sector.owner_color}} positions={coordinates}>
      <Popup>
        <div>
          <h3>{props.sector.name}</h3>
          <p>Owner: {props.sector.owner}</p>
          <ul>
            {props.sector.planets.map((planet, index) => {
              return (
                <li key={index}>{planet.name}</li>
              );
            })}
          </ul>
        </div>
      </Popup>
    </Polygon>
  );
}

// end_x: -220
// ​​​​​end_y: -255
// ​​​​​start_x: -235
// ​​start_y: -250
const HyperspaceLane = ({start_x, start_y, end_x, end_y, scale, offsetX, offsetY}) => {
  const coordinates = [[
    parseInt(start_x) * parseFloat(scale) + parseInt(offsetX),
    parseInt(start_y) * parseFloat(scale) + parseInt(offsetY)
  ], [
    parseInt(end_x) * parseFloat(scale) + parseInt(offsetX),
    parseInt(end_y) * parseFloat(scale) + parseInt(offsetY)
  ]];
  return (
    <Polyline pathOptions={{color: 'blue'}} positions={coordinates}/>
  );
}

const systemIcon = new Icon ({
  iconUrl : '/emoji/shipit.png',
  iconSize : [35,35], // size of the icon
  iconAnchor : [21,21], // point of the icon which will correspond to marker's location
  popupAnchor : [-3, -76] // point from which the popup should open relative to the iconAnchor

})

const convertSystemToMap = (x, y) => {
  const s = 0.13525388938283808;
  const theta = 2.621803411685809;
  const tx = 28.34306490482549;
  const ty = 7.470703125;

  const xRot = x * Math.cos(theta) - y * Math.sin(theta);
  const yRot = x * Math.sin(theta) + y * Math.cos(theta);

  const nx = xRot * s + tx;
  const ny = yRot * s + ty;

  // nan check
  return [
    isNaN(ny) ? 0 : ny, // lat
    isNaN(nx) ? 0 : nx, // lng
  ]
}
const SystemMarker = ({system, scale, offsetX, offsetY, scaleX, scaleY}) => {
  // const [lat, lng] = convertSystemToMap(system.x, system.y);
  // const x = system.x * parseFloat(scale) + parseInt(offsetX);
  // const y = system.y * parseFloat(scale) + parseInt(offsetY);
  const [lat, lng] = [system.y, system.x];
  console.log({offsetX, offsetY, lat, lng});
  return (
    <Marker
      position={[
        
        // parseFloat((lng * scaleX) + offsetX),
        // parseFloat((lat * scaleY) + offsetY),
        lat,
        lng
      ]}
      icon={
        divIcon({
          html: renderToStaticMarkup(
            <img src="/emoji/shipit.png" style={{ width: '35px', height: '35px' }} />
          ),
          iconSize: [35, 35],
        })
      }
    >
      <Popup>
        <div>
          <h3>{system.name}</h3>
          <p>x: {system.x}, y: {system.y}; lat: {lat}, lng: {lng}</p>
          <ul>
            {system.planets.map((planet, index) => {
              return (
                <li key={index}>{planet.name}</li>
              );
            })}
          </ul>
        </div>
      </Popup>
    </Marker>
  );
}



const Systems = ({systems, scale, offsetX, offsetY, scaleX, scaleY}) => {
  // console.log('Systems props:', props);
  return (
    <>
      {systems.map((system, index) => {
        return (
          <SystemMarker key={index} system={system} scale={scale} offsetX={offsetX} offsetY={offsetY} scaleX={scaleX} scaleY={scaleY} />
        );
      })}
    </>
  );
}
      
      function DraggableMarker() {
        const markerRef = React.useRef(null);
        const [position, setPosition] = React.useState([0, 0]);

        const eventHandlers = React.useMemo(() => ({
          dragend() {
            if (markerRef.current) {
              console.log(markerRef.current.getLatLng());
            }
          },
        }), []);

        return (
          <Marker
            position={position}
            icon={systemIcon}
            draggable
            eventHandlers={eventHandlers}
            ref={markerRef}
          >
            <Popup>Drag me</Popup>
          </Marker>
        );
      }

// This version won't flip y:
const MyCustomCRS = L.extend({}, L.CRS.Simple, {
  // scale stays the same
  scale: function (zoom) {
    return Math.pow(2, zoom);
  },
  // transformation: x' = x, y' = y
  transformation: new L.Transformation(1, 0, 1, 0),
});

const EachFeature = (feature, layer) => {
  // console.log('feature', feature);
  const { properties } = feature;
  if (properties) {
    const { name, description } = properties;

    if (name) {
      layer.bindPopup(name);
    }
    if (description) {
      layer.bindPopup(description);
    }
  }
}


const GalaxyMap = (props) => {
  // console.log('GalaxyMap props:', props);
  // const { isPending, error, data } = useQuery({
  //   refetchInterval: 6000,
  //   queryKey: ['ships', props.star_system.id],
  //   queryFn: () =>
  //     fetch(`/admin/star_systems/${props.star_system.id}/ships.json`).then((res) => res.json()
  //     ),
  // });
  // console.log('ships', data);
  const [scale, setScale] = React.useState(0.2);
  const [offsetX, setOffsetX] = React.useState(-122.5);
  const [offsetY, setOffsetY] = React.useState(121.5);

  // tile layer bounds: [0, -1], [256, -256]
  // systems range from x: -400 to 1800, y: -700 to 700
  // coruscant (0, 0 in db terms) is at about x: -95, y: 120
  const [systemScale, setSystemScale] = React.useState(0.0);
  const [systemScaleX, setSystemScaleX] = React.useState(1);
  const [systemScaleY, setSystemScaleY] = React.useState(1);
  const [systemOffsetX, setSystemOffsetX] = React.useState(128);
  const [systemOffsetY, setSystemOffsetY] = React.useState(128);
  console.log('systems', props.systems);
  const BOUND = 256;
  const bounds = [[0, 0], [BOUND, BOUND]];
  return (
    <Container>
      <label htmlFor="scale">Scale</label>
      <br/>
      <input type="range" min="-1" max="1" step="1" value={systemScaleX} onChange={(e) => setSystemScaleX(e.target.value)} />
      <input disabled value={systemScaleX} />
      <input type="range" min="-1" max="1" step="1" value={systemScaleY} onChange={(e) => setSystemScaleY(e.target.value)} />
      <input disabled value={systemScaleY} />
      <br/>
      <label htmlFor="offset">Offset</label>
      <br/>
      <input type="range" min="-256" max="256" step="0.01" value={systemOffsetX} onChange={(e) => setSystemOffsetX(e.target.value)} />
      <input disabled value={systemOffsetX} />
      <input type="range" min="-256" max="256" step="0.01" value={systemOffsetY} onChange={(e) => setSystemOffsetY(e.target.value)} />
      <input disabled value={systemOffsetY} />


      <MapContainer
        zoom={2}
        minZoom={0}
        maxZoom={6}
        scrollWheelZoom={false}
        style={{
          height: '600px',
          maxHeight: '600px',
          maxWidth: '800px',
          // "background-color": 'black',
          // float: 'left'
        }}
        crs={L.CRS.Simple}
        bounds = {bounds}
        center={[offsetX, offsetY]}
      >
        <TileLayer
          url='https://legacyofthesith.com/tiles/{z}/{x}/{y}.png'

          
        >

        </TileLayer>
        {/* {props.sectors.map((sector, index) => {
          return (
            <Sector key={index} sector={sector} scale={scale} offsetX={offsetX} offsetY={offsetY} />
          );
        })} */}
        {/* {props.lanes.map((hyperspace_lane, index) => {
          return (
            <HyperspaceLane key={index} {...hyperspace_lane} scale={scale} offsetX={offsetX} offsetY={offsetY} />);
        })} */}
        <Systems systems={props.systems} scale={systemScale} offsetX={systemOffsetX} offsetY={systemOffsetY} scaleX={systemScaleX} scaleY={systemScaleY} />
        <DraggableMarker />
        <Polyline pathOptions={{color: 'red'}} positions={[[0, 0], [0, BOUND]]}/>
        <Polyline pathOptions={{color: 'red'}} positions={[[0, 0], [BOUND, 0]]}/>
        <Polyline pathOptions={{color: 'red'}} positions={[[BOUND, 0], [BOUND, BOUND]]}/>
        <Polyline pathOptions={{color: 'red'}} positions={[[0, BOUND], [BOUND, BOUND]]}/>
        <Polyline
          pathOptions={{ color: 'blue' }}
          positions={[[-1000000, 0], [1000000, 0]]}
        />
        <Polyline
          pathOptions={{ color: 'blue' }}
          positions={[[0, -1000000], [0, 1000000]]}
        />

        <GeoJSON data={shiftedGeoData} onEachFeature={EachFeature} />
      </MapContainer>
    </Container>
  )
};

export default GalaxyMap;

// tile layer bounds: [0, -1], [256, -256]